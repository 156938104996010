import { __decorate } from "tslib";
import { LitElement } from 'lit';
import { customElement } from 'lit/decorators/custom-element.js';
import { style } from './shared/style.js';
import { svgTag } from './shared/svg-tag.js';
let IconCancel_Outlined = class IconCancel_Outlined extends LitElement {
    render() {
        return svgTag('<path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"/>');
    }
};
IconCancel_Outlined.styles = style;
IconCancel_Outlined = __decorate([
    customElement('mdui-icon-cancel--outlined')
], IconCancel_Outlined);
export { IconCancel_Outlined };
