import { $ } from '@mdui/jq/$.js';
import '@mdui/jq/methods/each.js';
import { uniqueId } from './uniqueId.js';
let weakMap;
// ResizeObserver 实例，所有 resizeObserver 函数内部共用一个 ResizeObserver 实例
let observer;
/**
 * 监听元素的尺寸变化
 * @param target 监听该元素的尺寸变化
 * @param callback 尺寸变化时执行的回调函数，`this` 指向监听的元素
 */
export const observeResize = (target, callback) => {
    const $target = $(target);
    const key = uniqueId();
    // 取消监听函数
    const result = {
        unobserve: () => {
            $target.each((_, target) => {
                const coArr = weakMap.get(target) ?? [];
                const index = coArr.findIndex((co) => co.key === key);
                if (index !== -1) {
                    coArr.splice(index, 1);
                }
                if (!coArr.length) {
                    observer.unobserve(target);
                    weakMap.delete(target);
                }
                else {
                    weakMap.set(target, coArr);
                }
            });
        },
    };
    // 初始化
    if (!weakMap) {
        weakMap = new WeakMap();
        observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const target = entry.target;
                const coArr = weakMap.get(target);
                coArr.forEach((co) => {
                    co.callback.call(result, entry, result);
                });
            });
        });
    }
    // 添加监听
    $target.each((_, target) => {
        observer.observe(target);
        const coArr = weakMap.get(target) ?? [];
        coArr.push({ callback, key });
        weakMap.set(target, coArr);
    });
    return result;
};
